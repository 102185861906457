import React from 'react';
import { useIntl } from 'react-intl';
import FAQ from '../components/Products/FAQ';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import ProductGuides from '../components/ProductGuides';

export default function EuDocs() {
  const intl = useIntl();

  return (
    <>
      <NavBar />
      <ProductGuides
        evachillDocList={[
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaCHILL/evaCHILL_DE.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.deutsch',
            }),
          },
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaCHILL/evaCHILL_FR.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.français',
            }),
          },
        ]}
        evalightplusDocList={[
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaLIGHTplus/evaLIGHTplus_DE.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.deutsch',
            }),
          },
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaLIGHTplus/evaLIGHTplus_FR.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.français',
            }),
          },
        ]}
        evasmartDocList={[
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaSMART/evaSMART_DE.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.deutsch',
            }),
          },
          {
            href: 'https://s3.amazonaws.com/pg.evapolar.com/nordics-eu/evaSMART/evaSMART_FR.pdf',
            language: intl.formatMessage({
              id: 'product.guides.language.français',
            }),
          },
        ]}
      />
      <FAQ />
      <Footer />
    </>
  );
}
